import React, { useEffect } from 'react';
import NotFound from '~/App/shared/components/NotFound';
import {
  DynamicPageQuery,
  DynamicPageDocument,
  OptionalHeroRecord,
  CarInspirationListRecord,
  TextSectionRecord
} from '~/config/generated/graphql';
import provideDataHook, {
  DataHookFunctionProps
} from '~/helpers/provideDataHook';
import { getCmsData } from '~/helpers/cmsDatoUtils';
import { isOnServer } from '~/helpers/isOnServer';
import { localizationSelector } from '~/App/shared/selectors/localization';
import { trimLeadingSlash } from '~/App/shared/localization/helpers';
import { useTranslation } from '~/Locale';
import { useLocation } from 'react-router';
import { metaHelper } from '~/helpers/seo';
import { getBaseCanonicalUrl } from '~/helpers/baseCanonicalUrl';
import Head from '~/App/shared/components/Head';
import OptionalHero from '~/App/components/OptionalHero';
import { isEmptyDocument } from 'datocms-structured-text-utils';
import StructuredText from '~/App/components/DatoCMS/StructuredText';
import { isValidSlug } from '~/helpers/pages';
import Auction from '~/App/shared/interfaces/Auction';
import { searchAuctions } from '~/helpers/orchestration/auctions';
import { extractParamsFromQueryString } from '~/helpers/extractParamsFromQueryString';
import { CmsData } from '~/App/shared/interfaces/store/CmsData';
import { scroller } from 'react-scroll';
import {
  HEADER_MIN_HEIGHT,
  HEADER_MIN_HEIGHT_MOBILE
} from '~/App/components/Header';
import { useIsDevice } from '~/App/shared/hooks/useIsDevice';
import Section, {
  BackgroundColor,
  ContentBackgroundColor,
  ContentBorderColor
} from '~/App/shared/components/Layout/Section';
import { RouteComponentProps } from '~/App/shared/interfaces/routes';
import KvdHelperWrapper from '~/App/shared/components/KvdHelperWrapper';

export type DataHookFetchedData = {
  cmsData: CmsData['dynamicPage'] | undefined | null;
  fetchedObjects: Record<string, Auction[]> | null;
};
type DataHook = Partial<DataHookFetchedData> & {
  ssrDataHookProcessed?: boolean;
};
type ComponentProps = RouteComponentProps;
type Props = RouteComponentProps & DataHook;

export const DynamicPage = ({
  staticContext,
  cmsData,
  fetchedObjects,
  ssrDataHookProcessed
}: Props) => {
  const { t } = useTranslation();
  const { pathname, hash } = useLocation();
  const { isMobile, isBeingDetermined } = useIsDevice();

  useEffect(() => {
    if (hash && !isBeingDetermined) {
      scroller.scrollTo(hash.replace('#', ''), {
        offset: -(isMobile ? HEADER_MIN_HEIGHT_MOBILE : HEADER_MIN_HEIGHT) - 20,
        duration: 200,
        smooth: true
      });
    }
  }, [hash, isMobile, isBeingDetermined]);

  const isServerSidePrepassRender =
    isOnServer &&
    typeof ssrDataHookProcessed === 'boolean' &&
    !ssrDataHookProcessed;

  if (!isServerSidePrepassRender && !cmsData) {
    return <NotFound staticContext={staticContext} />;
  }

  const seoTitle = cmsData?.seo?.title;
  const seoDescription = cmsData?.seo?.description;
  const seoImage = cmsData?.seo?.image?.responsiveImage?.src;

  const {
    contentMaxWidth,
    contentBackgroundColor,
    contentPadding,
    contentTextAlign,
    paddingTop,
    paddingBottom,
    backgroundColor,
    contentBorderColor,
    innerContentMaxWidth
  } = cmsData?.bodyStyles ?? {};

  return (
    <>
      <Head
        title={seoTitle ?? t('PAGE_META.DEFAULT_OG_TITLE')}
        meta={metaHelper({
          t,
          description: seoDescription,
          seoTitle,
          seoDescription,
          seoImage,
          url: pathname
        })}
        link={[{ rel: 'canonical', href: getBaseCanonicalUrl(pathname) }]}
      />
      <KvdHelperWrapper url={cmsData?.kvdHelperUrl ?? ''}>
        {cmsData?.hero && (
          <OptionalHero data={cmsData?.hero as OptionalHeroRecord} />
        )}
        {!isEmptyDocument(cmsData?.singleSectionBody) && (
          <Section
            backgroundColor={backgroundColor as BackgroundColor}
            maxWidth={(contentMaxWidth ?? 768) / 16}
            spacingTop={(paddingTop ?? 24) / 16}
            spacingBottom={(paddingBottom ?? 24) / 16}
            contentBackgroundColor={
              contentBackgroundColor as ContentBackgroundColor
            }
            contentPadding={contentPadding ? contentPadding : undefined}
            contentBorderColor={contentBorderColor as ContentBorderColor}
            innerContentMaxWidth={innerContentMaxWidth}
          >
            <StructuredText
              data={cmsData?.singleSectionBody}
              wrapperProps={{ contentTextAlign }}
              prefetchedData={{ objects: fetchedObjects }}
            />
          </Section>
        )}
        {!isEmptyDocument(cmsData?.multiSectionBody) && (
          <StructuredText
            data={cmsData?.multiSectionBody}
            prefetchedData={{ objects: fetchedObjects }}
          />
        )}
      </KvdHelperWrapper>
    </>
  );
};

export const dataHook = async ({
  location,
  dispatch,
  getState
}: DataHookFunctionProps & ComponentProps): Promise<DataHookFetchedData> => {
  const slug = decodeURIComponent(trimLeadingSlash(location.pathname));

  if (!isValidSlug(slug)) {
    return { cmsData: null, fetchedObjects: null };
  }

  const { currentLocale } = localizationSelector(getState());

  const cmsData = await getCmsData<DynamicPageQuery, CmsData['dynamicPage']>({
    dispatch,
    getState,
    storeKey: 'dynamicPage',
    storeNestedKey: slug,
    query: DynamicPageDocument,
    variables: { slug, locale: currentLocale }
  });

  if (
    cmsData?.singleSectionBody?.blocks?.length ||
    cmsData?.multiSectionBody?.blocks?.length
  ) {
    const carInspirationListRecords: CarInspirationListRecord[] = [];
    const appendCarInspirationBlock = (block: CarInspirationListRecord) => {
      if (
        block?.__typename === 'CarInspirationListRecord' &&
        block?.id &&
        block?.queryString?.split('?')?.length === 2
      ) {
        carInspirationListRecords.push(block);
      }
    };

    cmsData?.singleSectionBody?.blocks?.forEach(appendCarInspirationBlock);

    cmsData?.multiSectionBody?.blocks?.forEach(
      (sectionRecord: TextSectionRecord) =>
        sectionRecord?.body?.blocks?.forEach(appendCarInspirationBlock)
    );

    if (carInspirationListRecords?.length) {
      const results = await Promise.all(
        carInspirationListRecords.map(carInspiration =>
          searchAuctions({
            idToken: '',
            cancellable: false,
            offset: 0,
            limit: 20,
            params: extractParamsFromQueryString(
              carInspiration?.queryString ?? ''
            )
          })
        )
      );

      const fetchedObjects: Record<string, Auction[]> = {};
      carInspirationListRecords.forEach(({ id }, index) => {
        const auctions = results?.[index]?.auctions;
        if (auctions && id) {
          fetchedObjects[id] = auctions;
        }
      });
      return { cmsData, fetchedObjects };
    }
  }

  return { cmsData, fetchedObjects: null };
};

export default provideDataHook('dynamicPage', dataHook)(DynamicPage);
